import React from "react";

function Papers() {
  return (
    <div className="paper">
      <div className="container" style={{height: "70vh"}}>
        <div className="row">
          <h1>There are no papers currently</h1>
        </div>
        <div className="row">
          <p>TBD</p>
        </div>
      </div>
    </div>
  );
}

export default Papers;
